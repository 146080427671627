<template>
  <div class="stat-wrapper">
    <section class="load-pending" v-if="pageStatus == 'loading'">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="chartTitle">
        <h3 class="chartTitle__text">تعداد پیام ‌های ارسال شده</h3>
        <h4 class="chartTitle__number">
          {{ stat.count ? stat.count.toLocaleString() : 0 }}
        </h4>
      </div>
      <div class="stat-table">
        <div class="stat-table__row header--row">
          <h5 class="stat-table__cell">تاریخ</h5>
          <h5 class="stat-table__cell">زمان</h5>
          <h5 class="stat-table__cell">تعداد پیام</h5>
        </div>
        <div
          v-for="(notice, idx) in notices"
          :key="notice.id"
          :class="['stat-table__row', idx % 2 === 0 && 'even--row']"
        >
          <h5 class="stat-table__cell">
            {{ localizeDate(notice['created_at']) }}
          </h5>
          <h5 class="stat-table__cell">
            {{ getHour(notice['created_at']) }}
          </h5>
          <h5 class="stat-table__cell">
            {{ notice['count']?.toLocaleString() }}
          </h5>
        </div>
        <div class="stat-table__row" v-if="notices.length < 1">
          <h5 class="stat-table__cell">
            ارسال نشده
          </h5>
          <h5 class="stat-table__cell">
            0
          </h5>
          <h5 class="stat-table__cell">
            0
          </h5>
        </div>
      </div>
    </section>
    <failed-mode @refresh="getStats" v-else />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watchEffect } from 'vue'
import { getItems } from '@service/ItemService'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'

export default {
  name: 'NoticeStat',
  setup() {
    const { localizeDate, getHour } = dateConvertor()
    const store = useStore()
    const pageStatus = ref('loading')
    const notices = ref([])

    // Get all lotteries
    const getAllNotices = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await getItems('notice', { page: 1, limit: 50 })
        notices.value = [...data.results]
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }

    const getStats = () => {
      getAllNotices()
      // Get counts
      store.dispatch('fetchStat', { part: 'notice' })
    }
    getStats()

    // use to resolved data fetching to show page
    const stat = computed(() => store.state.stat)

    watchEffect(() => {
      if (stat.value && stat.value.count >= 0) pageStatus.value = 'resolved'
    })

    onBeforeUnmount(() => {
      store.dispatch('clearStat')
    })
    return { notices, localizeDate, getHour, stat, getStats, pageStatus }
  }
}
</script>

<style scoped lang="scss">
.stat-wrapper {
  width: 100%;
  padding: 5px 0 20px;
  // background: linear-gradient(to bottom, #000 30%, #333 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
