<template>
  <div class="notice-wrapper">
    <div class="filtered-customers">
      <span
        class="count"
        v-text="customersCount ? customersCount.toLocaleString() : 0"
      />
      <h3 class="title">مشتری آماده دریافت اطلاع رسانی</h3>
    </div>
    <form novalidate class="form-wrapper" @submit.prevent="submitForm">
      <div class="has-name">
        <p class="has-name__text">
          "نام مشتری" به ابتدای پیام افزوده ‌شود؟
        </p>
        <span class="has-name__ex">
          مثال: "خانم سارا محمدی عزیز" یا " آقای علی رضایی عزیز"
        </span>
        <div class="credit-buttons" style="margin: 0">
          <button
            @click="formItems['noticeHasName'] = !formItems['noticeHasName']"
            type="button"
            :class="[
              'button',
              'button--default',
              formItems['noticeHasName'] && 'button--active'
            ]"
          >
            <span class="button__text">{{
              formItems['noticeHasName'] ? 'بله' : 'خیر'
            }}</span>
          </button>
        </div>
      </div>
      <div class="bta-wrapper">
        <box-textarea label="متن پیام" v-model="formItems['noticeMessage']" />
      </div>
      <a class="img">
        <img class="img__img" v-if="imgBase64" :src="imgBase64" />
        <input
          class="img__input"
          type="file"
          name="file-input"
          accept="image/*"
          @change="handleSelectImg"
        />
        <div class="img__outer" v-if="!imgBase64">
          <div class="img__inner">
            <svg class="img__icon" viewBox="0 0 512 512">
              <path
                d="M446.575,0H65.425C29.349,0,0,29.35,0,65.426v381.149C0,482.65,29.349,512,65.425,512h381.15
			C482.651,512,512,482.65,512,446.574V65.426C512,29.35,482.651,0,446.575,0z M481.842,446.575
			c0,19.447-15.821,35.267-35.267,35.267H65.425c-19.447,0-35.268-15.821-35.268-35.267v-55.007l99.255-84.451
			c3.622-3.082,8.906-3.111,12.562-0.075l62.174,51.628c5.995,4.977,14.795,4.569,20.304-0.946L372.181,209.77
			c2.67-2.675,5.783-2.935,7.408-2.852c1.62,0.083,4.695,0.661,7.078,3.596l95.176,117.19V446.575z M481.842,279.865l-71.766-88.366
			c-7.117-8.764-17.666-14.122-28.942-14.701c-11.268-0.57-22.317,3.672-30.294,11.662L212.832,326.681l-51.59-42.839
			c-14.959-12.422-36.563-12.293-51.373,0.308l-79.712,67.822V65.426c0-19.447,15.821-35.268,35.268-35.268h381.15
			c19.447,0,35.267,15.821,35.267,35.268V279.865z"
              />
              <path
                d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713
			S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556
			c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
              />
            </svg>
          </div>
        </div>
        <button
          class="img__remove"
          v-if="imgBase64"
          type="button"
          @click="removeImg"
        >
          &times;
        </button>
      </a>
      <!-- <div class="table table--form" style="width: 100%;margin: 12px 0 18px">
        <div class="table__row">
          <h6 class="table__cell cell--title">
            تعداد مشتری
          </h6>
          <h6 class="table__cell cell--amount">
            {{ customersCount.toLocaleString() }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h6 class="table__cell cell--title">
            ارسال هر پیام
          </h6>
          <h6 class="table__cell cell--price ">
            {{ noticeTablePrice.toLocaleString() }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h6 class="table__cell cell--title">
            هزینه کل
          </h6>
          <h6 class="table__cell cell--price ">
            {{ finalPrice.toLocaleString() }}
          </h6>
        </div>
      </div> -->
      <div class="credit-buttons">
        <button type="submit" class="button button--black">
          <span class="button__text">ارسال</span>
          <spinner v-if="btnLoading" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, useStore } from 'vuex'
import { addNotice } from '@service/NoticeService'
import { schemaMaker, reqString, validateForms } from '@valid/JoiValidation'
import BoxTextarea from '@common/BoxTextarea'
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import imgResizer from '@composable/imgResizer'

const isFormValid = schemaMaker({
  message: reqString('متن پیام')
})

export default {
  name: 'NoticeNew',
  components: {
    BoxTextarea
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    // refresh check for filters
    const isFilled = computed(() => store.state.customer.filterIsFilled)
    if (!isFilled.value) {
      router.push({
        name: 'NoticeCustomerFilter',
        params: { destination: 'notice' }
      })
    }
    // Define main items
    const modals = reactive({
      image: false
    })
    const btnLoading = ref(false)
    const isDone = ref(false)
    const formItems = reactive({
      noticeHasName: false,
      noticeMessage: '',
      noticeImg: ''
    })
    // Initial API calls
    // customers number
    const customersCount = computed(
      () => store.state.customer.filteredCusotmerCount
    )
    const noticeTablePrice = 500
    const finalPrice = computed(() => customersCount.value * noticeTablePrice)
    // Handle images
    const imgResizerItems = imgResizer()

    return {
      formItems,
      btnLoading,
      isDone,
      modals,
      customersCount,
      noticeTablePrice,
      finalPrice,
      // images
      ...imgResizerItems
    }
  },
  methods: {
    async submitForm() {
      if (this.btnLoading || this.isDone) return
      const { noticeMessage: message, noticeHasName: hasName } = this.formItems
      const errors = validateForms({ message }, isFormValid)
      if (errors && errors.length > 0) {
        for (let key of errors) {
          const message = { type: 'error', message: key }
          this.$store.dispatch('addToast', message)
        }
        return
      }

      this.btnLoading = true

      //  init final form and add filters
      const finalForm = { ...this.filters }

      finalForm['message'] = message
      finalForm['include_name'] = hasName ? 1 : 0
      if (this.imgBase64) {
        finalForm['image'] = this.imgFile
      }
      try {
        await addNotice(finalForm)
        this.btnLoading = false
        this.isDone = true
        this.$store.dispatch('addToast', {
          type: 'success',
          message: 'اطلاع رسانی با موفقیت انجام شد.'
        })

        this.$store.dispatch('fetchHomeData')
        setTimeout(() => this.$router.push({ name: 'NoticeHome' }), 2000)
      } catch (ex) {
        if (ex.response) {
          switch (ex.response.status) {
            case 402:
              this.$store.dispatch('profile/handleCreditModal', {
                status: 'open',
                type: 'credit'
              })
              break
            default:
              this.addToast({
                type: 'error',
                message: 'درخواست شما موفقیت‌آمیز نبود.'
              })
          }
        }
        this.btnLoading = false
      }
    }
  },
  computed: {
    ...mapState({
      filters: (state) => state.customer.filterData
    })
  }
}
</script>

<style lang="scss" scoped>
.notice-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}
.bta-wrapper {
  width: 100%;
}

.has-name {
  width: 100%;
  padding: 30px 0;
  margin-bottom: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
}
.has-name__text {
  width: 100%;
  text-align: center;
  margin: 0 0 8px;
}
.has-name__ex {
  font-size: 12px;
  color: #aaa;
  margin: 0 0 12px;
}

// Img Block
.img {
  margin: 30px 0 18px;
  overflow: hidden;
  position: relative;
  width: 100%;
  color: white;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.img__img {
  width: 100%;
  max-width: 600px;
}
.img__outer {
  // use to set height
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.img__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 100%;
  background-color: #ddd;
  pointer-events: none;
}
.img__icon {
  width: 40px;
  height: 40px;
  fill: #fff;
  pointer-events: none;
}
.img__text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
}
.img__input {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}
.img__remove {
  display: block;
  text-align: center;
  width: 100%;
  height: 50px;
  color: #777;
  z-index: 11;
  font-size: 20px;
}

// change in common css
.credit-buttons {
  justify-content: center;
  margin: 12px 0 30px;
}
.buttons--add {
  margin: 0 0 20px;
}
.buttons--edit {
  margin: 0 0 20px;
  justify-content: space-between;
}
.buttons--edit .button--black {
  width: calc(50% - 5px);
}
.button--black {
  width: 100%;
}
.button--default {
  background-color: #ddd;
  width: 220px;
  height: 70px;
}
.button--default .button__text {
  color: #111;
  font-size: 16px;
  margin-left: 10px;
}
.button--default .button__icon {
  fill: #fff;
  background-color: #fff;
  border-radius: 4px;
  // border: 1px solid #777;
}
.button--active {
  background-color: #000;
}
.button--active .button__icon {
  fill: #fff;
  background-color: #222;
  border-radius: 4px;
  border: none;
}
.button--active .button__text {
  color: #fff;
}
.button--remove {
  background-color: #d40000;
  width: calc(50% - 5px);
}
.button--remove .button__text {
  color: #fff;
}
.form-wrapper {
  width: 90%;
  margin-top: 15px;
}
</style>
