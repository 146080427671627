<template>
  <div :class="['notice']">
    <img class="notice__img" src="@img/notice-back.jpg" />
    <svg
      class="notice__logo"
      width="700px"
      height="512px"
      viewBox="0 0 700 512"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          d="M235.15 148.21c-62.71,0 -113.55,50.84 -113.55,113.56 0,62.71 50.84,113.55 113.55,113.55 62.72,0 113.56,-50.84 113.56,-113.55 0,-62.72 -50.84,-113.56 -113.56,-113.56zm229.91 -141.31c-4.06,-3.66 -7.19,-6.9 -13.05,-6.9 -7.47,0 -13.53,6.06 -13.53,13.53 0,7.1 4.52,10.13 9.28,14.45 66.02,60.08 103.41,144.46 103.41,233.79 0,85.7 -34.4,166.98 -95.86,226.68 -2.7,2.63 -4.31,6.24 -4.31,10.02 0,7.47 6.06,13.53 13.53,13.53 3.23,0 6.33,-1.14 8.79,-3.24 1.06,-0.91 2.09,-1.88 3.08,-2.86 65.47,-64.69 102,-152.07 102,-244.13 0,-97.52 -41.01,-189.58 -113.34,-254.87zm-89.31 429.21c5.94,0 9.55,-3.89 13.6,-7.63 46.6,-43.12 72.91,-103.19 72.91,-166.71 0,-63.9 -26.62,-124.28 -73.69,-167.43 -3.88,-3.55 -6.32,-6.16 -11.93,-6.16 -7.48,0 -13.54,6.06 -13.54,13.54 0,2.71 0.8,5.33 2.32,7.58 1.77,2.6 5.07,4.98 7.39,7.18 40.01,37.92 62.5,90.14 62.5,145.29 0,56.46 -23.59,109.81 -65.26,147.85 -4.58,4.18 -7.83,6.15 -7.83,12.96 0,7.48 6.06,13.53 13.53,13.53zm-140.6 -260.6c47.64,0 86.26,38.62 86.26,86.26 0,47.64 -38.62,86.26 -86.26,86.26 -47.64,0 -86.26,-38.62 -86.26,-86.26 0,-47.64 38.62,-86.26 86.26,-86.26z"
        />
      </g>
    </svg>
    <div class="notice__left">
      <h3 class="notice__title" v-text="message" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'noticeSingle',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
  position: relative;
  width: 96%;
  border-radius: 20px;
  background-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0;
  height: 110px;
  justify-content: flex-end;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
}

.notice__img {
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
}

.notice__left {
  width: calc(100% - 120px);
  z-index: 1;
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notice__title {
  width: 100%;
  font-size: 14.2px;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  color: #111;
}
.notice__time {
  margin: 0 0 0;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  color: #333;
}
.time--hour {
  font-weight: bold;
  color: #aaa;
  font-size: 16px;
}

.notice__logo {
  position: absolute;
  z-index: 2;
  right: 30px;
  width: 60px;
  height: 60px;
  fill: #c69d4e;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
</style>
