<template>
  <div class="message-wrapper">
    <navbar msg="جزئیات اطلاع رسانی" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="time-detail">
        <div class="time-detail__col">
          <p class="time-detail__text">زمان</p>
          <h4 class="time-detail__num">
            {{ getHour(notice['created_at']) }}
          </h4>
        </div>
        <i class="time-detail__vr" />
        <div class="time-detail__col">
          <p class="time-detail__text">تاریخ</p>
          <h4 class="time-detail__num">
            {{ localizeDate(notice['created_at']) }}
          </h4>
        </div>
      </div>
      <div class="has-name">
        <p class="has-name__text">"نام مشتری" به ابتدای پیام اضافه شده است؟</p>
        <span class="has-name__ans" v-text="includeName" />
      </div>
      <h5 class="title">متن پیام</h5>
      <p class="message" v-text="notice['message']" />
      <div class="img">
        <img :src="notice ? notice['image'] : null" />
      </div>
      <div class="count">
        <h5 class="count__number">{{ notice['count']?.toLocaleString() }}</h5>
        <h6 class="count__text">مشتری پیام را دریافت کرده‌اند</h6>
      </div>
      <filters-table :filters="notice?.filters" />
    </section>
    <failed-mode
      @refresh="$store.dispatch('notice/fetchItem', { part: 'notice', id })"
      v-else
    />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'
import FiltersTable from '@common/FiltersTable'

export default {
  name: 'NoticeShow',
  components: {
    FiltersTable
  },
  props: ['id'],
  setup(props) {
    const store = useStore()
    store.dispatch('notice/fetchItem', { part: 'notice', id: props.id })

    const notice = computed(() => store.state.notice.item)
    const pageStatus = computed(() => store.state.notice.pageStatus)

    const includeName = computed(() => {
      if (notice.value['include_name']) return 'بله'
      else return 'خیر'
    })

    const { localizeDate, getHour } = dateConvertor()

    onBeforeUnmount(() => {
      store.dispatch('notice/clearItem')
    })

    return { notice, includeName, localizeDate, getHour, pageStatus }
  }
}
</script>

<style scoped lang="scss">
.message-wrapper {
  background-color: #eee;
  overflow: hidden;
  width: 100%;
  padding: 60px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.message-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time {
  display: flex;
  direction: ltr;
  align-items: center;
  justify-content: center;
  width: 220px;
  margin: 20px 0 0;
  background-color: #ddd;
  height: 60px;
  border-radius: 15px;
}
.time__date {
  // font-size: 18px;
  position: relative;
  top: 2px;
}
.time__hour {
  // font-size: 14px;
  position: relative;
  top: 2px;
}
.time__vr {
  height: 42.5%;
  width: 1px;
  background-color: #eee;
  margin: 0 10px;
}

.title {
  width: 100%;
  margin-bottom: 10px;
  color: #777;
  text-align: center;
}
.message {
  margin: 0;
  width: 90%;
  text-align: center;
  text-align-last: center;
  font-size: 14px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px 0;
}

.has-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}
.has-name__text {
  margin-bottom: 10px;
}
.has-name__ans {
  display: inline-block;
  background-color: #ddd;
  width: 50px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
}

.img {
  margin: 30px 0;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 600px;
    border-radius: 15px;
  }
}

.count {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 0 30px;
}
.count__number {
  margin: 0 5px;
  font-size: 52px;
  font-weight: bold;
}

.time-detail {
  background-color: #ddd;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 20px;
  width: 96%;
  border-radius: 15px;
}
.time-detail__vr {
  width: 1px;
  height: 60%;
  background-color: rgba(#000, 0.3);
}
.time-detail__col {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time-detail__text {
  font-size: 14px;
  margin-bottom: 10px;
}
.time-detail__num {
  // animation: blinking 1.3s infinite;
  font-size: 16px;
}

// change in common css
.stat-table {
  margin-top: 10px;
}
.stat-table__row {
  grid-template-columns: 1fr 1fr;
}
.load-pending--show {
  height: calc(100vh - 140px);
}
</style>
