<template>
  <section class="list">
    <notice-single
      v-for="notice in notices"
      :key="notice.id"
      :message="notice['message']"
      @click="enterNotice(notice.id)"
    />
    <empty-part
      desc-top="اطلاع رسانی"
      desc-bottom="اطلاع رسانی"
      v-if="pageStatus == 'resolved' && notices.length === 0"
    />
    <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
    <loader v-if="pageStatus == 'loading'" />
    <observer @intersect="intersected" />
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import EmptyPart from '@common/EmptyPart'
import NoticeSingle from './subComponents/NoticeSingle'

export default {
  components: {
    NoticeSingle,
    EmptyPart
  },
  setup() {
    // Fetch Bonuses
    const store = useStore()
    store.dispatch('notice/fetchItems', { part: 'notice' })
    const notices = computed(() => store.state.notice.items)
    const pageStatus = computed(() => store.state.notice.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('notice/fetchItems', { part: 'notice' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterNotice = (id) => {
      router.push({ name: 'NoticeShow', params: { id } })
    }

    // Clear the room
    onBeforeUnmount(() => store.dispatch('notice/clearItems'))

    // ------- //
    return { notices, intersected, enterNotice, pageStatus }
  }
}
</script>
